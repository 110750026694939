import React, { Component } from "react";
import CartContext from "./CartContext";
import { Navigate } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import "../CSS/3d.css";
import Swal from "sweetalert2";
import PosInvoiceTemplate from "./PosInvoice.txt";

import {
  FloatingLabel,
  Button,
  Table,
  Modal,
  Form,
  Row,
  Col,
  Stack,
} from "react-bootstrap";

export default class CheckoutPOS extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      registeredUserAccount: "",
      guestUserAccount: "",
      firstName: "",
      lastName: "",
      emailId: "",
      mobileNo: "",
      address: "",
      landmark: "",
      country: "",
      state: "",
      city: "",
      pinCode: "",
      paymentmode: "",
      formValidationErrorMsg: "",
      validatedAndSubmitted: "false",
      errormsg: "",
      loadSpinnerOpen: false,
      hideDifferentAddress: "true",
      deliveryAddressState: "current", //current or different
      showCodModal: false,
      showPayByCashModal: false,
      showPayByQrCodeModal: false,
      showPayByCardSwipingMachineModal: false,
      orderTypeOrderDelivered: this.props.orderTypeOrderDelivered,
      orderTypeTakeAway: this.props.orderTypeTakeAway,

      orderTypeHomeDelivery: this.props.orderTypeHomeDelivery,
      razorPayWindow: "",
      quickPayEvent: false,
    };
    this.keyMap = {
      TEST1: "t",
    };
  }
  static PaymentMode_CashOnDelivery = 1;
  static PaymentMode_RazorPay = 2;
  static OrderStatusRecived = 1;

  onKeyUp = (keyName, e, handle) => {
    console.log("test:onKeyUp", e, handle);
  };
  onKeyDown = (keyName, e, handle) => {
    console.log("test:onKeyDown", keyName, e, handle);
  };

  KeyDownHandlers = {
    TEST1: () => {},
  };

  handleChangeOrderType = (event) => {
    this.setState({ orderTypeOrderDelivered: false });
    this.setState({ orderTypeTakeAway: false });

    this.setState({ orderTypeHomeDelivery: false });
    switch (event.target.id) {
      case "InStore":
        this.setState({ orderTypeOrderDelivered: true });
        break;
      case "TakeAway":
        this.setState({ orderTypeTakeAway: true });
        break;

      case "HomeDelivery":
        this.setState({ orderTypeHomeDelivery: true });
        break;
    }
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "firstName":
        this.setState({ firstName: value });
        break;
      case "mobileNo":
        if (value.toString().length <= 10) {
          this.setState({ mobileNo: value });
        }
        break;
      case "emailId":
        this.setState({ emailId: value });
        break;
      case "address":
        this.setState({ address: value });
        break;
    }
  };

  CallRazorPay = () => {
    var ordid = "Order_" + new Date().getTime();
    var custName = "";
    var emailId = "";
    var mobileNo = "";

    var options = {
      key: this.context.storeSettings.razorpayKey,
      key_secret: this.context.storeSettings.razorpayKeySecret,
      // amount: 1 * 100,
      amount:
        Math.round(
          this.context.getTotalAmountWithTax(
            this.context.getPOSCartByName(this.context.selectedPOS),
            this.props.coupon.couponValue
          ),
          0
        ) * 100,
      currency: this.context.storeSettings.razorpayCurrency,
      name: this.context.storeSettings.razorpayCompanyName,
      description: this.context.storeSettings.razorpayCompanyDescription,
      orderid: ordid,
      handler: (response) => {
        this.SuccessRazorPayResponse(response);
      },
      prefill: {
        name: custName, //customer name
        email: emailId, //customer emailId
        contact: mobileNo, //customer phoneno
      },
      notes: {
        address: this.context.storeSettings.razorpayCompanyAddress,
      },
      theme: {
        color: this.context.storeSettings.razorpayThemeColor,
      },
      modal: {
        escape: false,
        ondismiss: () => {
          //this.setState({ OrderStatus: "Dismissed" });
          // this.context.UpdateOrderStatus("Dismissed");
        },
      },
    };
    var pay = window.Razorpay(options);
    //var rzp1 = new Razorpay(options);
    //pay.on("payment.failed", function (response) {
    pay.on("payment.failed", (response) => {
      this.FailedInRazorPayResponse(response);
    });
    pay.open();
  };
  FailedInRazorPayResponse = (response, pay) => {
    //TODO: store the response details in Database
    // this.context.UpdateOrderStatus("Failed");
    //this.setState({ OrderStatus: "Failed" });
    // alert(response.error.code);
    // alert(response.error.description);
    // alert(response.error.source);
    // alert(response.error.step);
    // alert(response.error.reason);
    // alert(response.error.metadata.order_id);
    // alert(response.error.metadata.payment_id);
  };
  SuccessRazorPayResponse = (response) => {
    //1. Store Order Details in DB
    this.StoreOrderInDB(response.razorpay_payment_id);
    //2. Clear Local Cart
    console.log(response.razorpay_payment_id);
  };
  PerformCashOnDelivery = () => {
    //TODO: store guest user details in database.
    this.StoreOrderInDB("");
  };

  ConvertProductNamesTo2ndLanguage = (orderData) => {
    for (let i = 0; i < orderData.customerOrderedItems.length; i++) {
      var result = this.context.prodsAll.filter(
        (f) => f.name == orderData.customerOrderedItems[i].productName
      );
      if (result.length > 0) {
        if (result[0].name2)
          orderData.customerOrderedItems[i].productName = result[0].name2;
      }
    }
  };

  PrintInvoiceOffline = (value) => {
    fetch(PosInvoiceTemplate)
      .then((r) => r.text())
      .then((htmltxt) => {
        //var htmltxt = this.state.PosInvoiceTemplate;
        htmltxt = htmltxt.replace("{{StoreName}}", this.context.store.name);

        htmltxt = htmltxt.replace(
          "{{StoreAddress}}",
          this.context.shippingDetails.address +
            ", " +
            this.context.shippingDetails.city +
            ", " +
            this.context.shippingDetails.state +
            ", " +
            this.context.shippingDetails.pincode
        );
        if (
          this.context.storeSettings.storeEmailId != null &&
          this.context.storeSettings.storeEmailId != ""
        )
          htmltxt = htmltxt.replace(
            "{{StoreEmailid}}",
            this.context.storeSettings.storeEmailId
          );
        else htmltxt = htmltxt.replace("{{StoreEmailid}}", "");

        if (
          this.context.storeSettings.storePhoneNo != null &&
          this.context.storeSettings.storePhoneNo != ""
        )
          htmltxt = htmltxt.replace(
            "{{StoreContact}}",
            "+" +
              this.context.storeSettings.defaultCountryCode +
              "-" +
              this.context.storeSettings.storePhoneNo
          );
        else htmltxt = htmltxt.replace("{{StoreContact}}", "");

        htmltxt = htmltxt.replace("{{Order_No}}", value.orderId);
        htmltxt = htmltxt.replace(
          "{{Order_Date}}",
          this.context.GetInDateFormat(new Date())
        );

        var orderedItems = "";
        var totqty = 0;
        var tr_start = "<tr>";
        var tr_end = "</tr>";
        var td_start = "<td>";
        var td_end = "</td>";
        for (var i = 0; i < value.customerOrderedItems.length; i++) {
          orderedItems += tr_start;
          if (value.customerOrderedItems[i].discount > 0)
            orderedItems +=
              '<td align="start">' +
              value.customerOrderedItems[i].productName +
              "[MRP." +
              Number(value.customerOrderedItems[i].mrp).toFixed(1) +
              " " +
              value.customerOrderedItems[i].discount +
              "% OFF]" +
              td_end;
          else
            orderedItems +=
              '<td align="start">' +
              value.customerOrderedItems[i].productName +
              td_end;

          orderedItems +=
            td_start +
            Number(
              value.customerOrderedItems[i].mrp -
                (value.customerOrderedItems[i].mrp *
                  value.customerOrderedItems[i].discount) /
                  100
            ).toFixed(1) +
            td_end;

          orderedItems += td_start + value.customerOrderedItems[i].qty + td_end;

          orderedItems +=
            '<td align="end">' +
            Number(
              (value.customerOrderedItems[i].mrp -
                (value.customerOrderedItems[i].mrp *
                  value.customerOrderedItems[i].discount) /
                  100) *
                value.customerOrderedItems[i].qty
            ).toFixed(1) +
            td_end;
          totqty += Number(value.customerOrderedItems[i].qty);

          orderedItems += tr_end;
        }

        htmltxt = htmltxt.replace("{{Ordered_Items}}", orderedItems);
        htmltxt = htmltxt.replace("{{TotalQty}}", Number(totqty).toFixed(1));
        htmltxt = htmltxt.replace(
          "{{subtotal}}",
          Number(value.subTotalAmt).toFixed(1)
        );

        var extracharges = "";
        if (value.couponValue > 0) {
          extracharges += "Coupon Value: " + value.couponValue + "<br/>";
        }
        if (value.deliveryAmt > 0) {
          extracharges +=
            "Delivery Charges: " +
            Number(value.deliveryAmt).toFixed(1) +
            "<br/>";
        }
        if (value.taxPercentage > 0) {
          if (this.context.store.businessTypeId == 3) {
            extracharges +=
              "CGST " +
              value.taxPercentage / 2 +
              "%: &nbsp;&nbsp;&nbsp;" +
              Number(value.taxAmount / 2).toFixed(1) +
              "<br/>";
            extracharges +=
              "SGST " +
              value.taxPercentage / 2 +
              "%: &nbsp;&nbsp;&nbsp;" +
              Number(value.taxAmount / 2).toFixed(1) +
              "<br/>";
          } else {
            extracharges +=
              "Taxes(" +
              value.taxPercentage +
              "%): " +
              Number(value.taxAmount).toFixed(1) +
              "<br/>";
          }
        }
        htmltxt = htmltxt.replace("{{Extra_charges}}", extracharges);
        htmltxt = htmltxt.replace(
          "{{GrandTotal}}",
          "Total= " + Number(value.totalAmt).toFixed(1)
        );
        if (value.totalSavings > 0)
          htmltxt = htmltxt.replace(
            "{{Savings}}",
            "Savings= " + Number(value.totalSavings).toFixed(1)
          );
        else htmltxt = htmltxt.replace("{{Savings}}", "");

        if (
          this.context.storeSettings.homeDeliveryDomainAddress != "" &&
          this.context.storeSettings.homeDeliveryDomainAddress != null
        )
          htmltxt = htmltxt.replace(
            "{{homedeliveryaddress}}",
            "Home Delivery: <b>" +
              this.context.storeSettings.homeDeliveryContactNumber +
              "  " +
              this.context.storeSettings.homeDeliveryDomainAddress +
              "</b>"
          );
        else htmltxt = htmltxt.replace("{{homedeliveryaddress}}", "");

        this.PrintTableOffline(htmltxt);
      });
  };

  PrintTableOffline = (invoiceHtml) => {
    var printWindow = window.open("", "_blank");
    printWindow.document.write(invoiceHtml);
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };

  GetPosInvoiceHtml = (orderData) => {
    if (this.context.storeSettings.printPosBillIn2NdLanguage == true)
      this.ConvertProductNamesTo2ndLanguage(orderData);

    var res = axios
      .post(
        process.env.REACT_APP_API + "Consumers/GetPosInvoiceHtml/",
        orderData
      )
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          // this.setState({ invoiceHtml: response.data });
          this.PrintTable(response.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("GetInvoiceHtml - There was an error! ", error);
      });
  };

  PrintTable = (invoiceHtml) => {
    // var printWindow = window.open("", "", "height=500,width=1000");
    // window.setTimeout(function () {
    // }, 1000);
    var printWindow = window.open("", "_blank");
    printWindow.document.write(invoiceHtml);
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };

  getOS = () => {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes("Mac") && "ontouchend" in document)
    )
      return "iOS";

    var i,
      os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], "i").test(uA)) return os[i];
  };

  onPrintFinished = (status, printWindow) => {
    var osvar = this.getOS();
    if (osvar == "Windows") printWindow.close();
  };

  getMainCategoryById = (id) => {
    var pg = this.context.mainCategories.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };

  GetOfflineOrderId = () => {
    var d = new Date();
    //get the month
    var month = d.getMonth();
    //get the day
    //convert day to string
    var day = d.getDate().toString().padStart(2, "0");
    //get the year
    var year = d.getFullYear();

    //pull the last two digits of the year
    year = year.toString().substr(-2);

    //increment month by 1 since it is 0 indexed
    //converts month to a string
    month = (month + 1).toString().padStart(2, "0");

    var hh = d.getHours().toString();
    var mm = d.getMinutes().toString();
    var ss = d.getSeconds().toString();

    //return the string "MMddyy"
    var result = this.context.pcCode + year + month + day + hh + mm + ss;
    return result;
  };

  ValidateCART = () => {
    var result = true;
    var cart = this.context.getPOSCartByName(this.context.selectedPOS);
    for (var i = 0; i < cart.length; i++) {
      {
        if (cart[i].prod.mrp > 0 && cart[i].qty > 0) {
        } else {
          result = false;
          break;
        }
      }
    }
    return result;
  };

  StoreOrderInDB = (paymentTransactionId) => {
    //this.ShowLoadSpinner();
    if (this.ValidateCART() == false) {
      Swal.fire({
        title: "Invalid CART!",
        text: "Price and Qty should be greater than 0. Please fix the errors in the Cart and proceed!",
        confirmButtonText: "ok",
        //timer: 1500,
      }).then((result) => {});
      return;
    }

    var userid = "";
    var paymentmodeid = 0; //cashondelivery
    var paymentstatusid = 0;
    var orderstatusid = 0;
    var deliveryMethodId = 0;
    var description = "";
    var orderWorkflowStatus =
      "<p>" +
      this.context.GetInDateFormat(new Date()) +
      "=> " +
      "POS-PaidAndClosed" +
      "</p>";

    if (this.state.orderTypeOrderDelivered)
      orderWorkflowStatus =
        "<p>" +
        this.context.GetInDateFormat(new Date()) +
        "=> " +
        "POS-PaidAndClosed" +
        "</p>";
    else if (this.state.orderTypeTakeAway || this.state.orderTypeHomeDelivery)
      orderWorkflowStatus =
        "<p>" +
        this.context.GetInDateFormat(new Date()) +
        "=> " +
        "Received" +
        "</p>";

    var totalAmtPaid = 0;
    var customerOrderedItems = [];
    var customerOrderDeliveryAddresses = [
      {
        firstName: this.state.firstName,
        lastName: "",
        mobileNo: this.state.mobileNo,
        emailId: this.state.emailId,
        Address: this.state.address,
        Landmark: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
      },
    ];

    this.context.getPOSCartByName(this.context.selectedPOS).map((cartItem) => {
      var OrderedItem = {
        productId: "1",
        productName: "",
        imgFileName: "",
        price: 0,
        qty: "",
        description: "",
      };
      if (cartItem.prod.id.includes("Custom") == false)
        OrderedItem.productId = cartItem.prod.id;
      else OrderedItem.productId = undefined;
      OrderedItem.productName = cartItem.prod.name;
      OrderedItem.categoryName = this.getMainCategoryById(
        cartItem.prod.mainCategoryId
      );

      OrderedItem.imgFileName = cartItem.prod.imgFileName;
      OrderedItem.mrp = cartItem.prod.mrp;
      OrderedItem.discount = cartItem.prod.discount;
      OrderedItem.qty = cartItem.qty;
      OrderedItem.description = cartItem.prod.description;
      customerOrderedItems.slice();
      customerOrderedItems.push(OrderedItem);
    });

    paymentmodeid = this.state.paymentmode;

    if (this.state.orderTypeOrderDelivered) {
      paymentstatusid = paymentmodeid == 6 ? 2 : 1; //if paywithinvoice unpaid else paid
      orderstatusid = paymentmodeid == 6 ? 8 : 11; //if paywithinvoice,DeliveredButUnpaid else Closed
      deliveryMethodId = 1; // Delivered or Instore purchase

      if (paymentmodeid != 6) {
        totalAmtPaid = this.context.getTotalAmountWithTax(
          this.context.getPOSCartByName(this.context.selectedPOS),
          this.props.coupon.couponValue
        );
      }
    } else if (this.state.orderTypeTakeAway) {
      if (paymentmodeid == 1) {
        //COD
        paymentstatusid = 2; //if COD unpaid else paid
        orderstatusid = 2; //Received
        deliveryMethodId = 2; //TakeAway
      } else {
        paymentstatusid = 1; //if COD unpaid else paid
        orderstatusid = 2; //Received
        deliveryMethodId = 2; //TakeAway
        totalAmtPaid = this.context.getTotalAmountWithTax(
          this.context.getPOSCartByName(this.context.selectedPOS),
          this.props.coupon.couponValue
        );
      }
    } else if (this.state.orderTypeHomeDelivery) {
      paymentstatusid = 2; //unpaid
      orderstatusid = 2; //Received
      deliveryMethodId = 3; //Home Delivery
      totalAmtPaid = 0;
    }

    var str = {
      storeId: this.context.storeId,
      orderCreated:
        this.context.isOfflineMode == true ? new Date().toISOString() : null,
      orderModified:
        this.context.isOfflineMode == true ? new Date().toISOString() : null,
      orderId:
        this.context.isOfflineMode == true ? this.GetOfflineOrderId() : null,
      customerId: this.context.userData.id,
      paymentModeId: paymentmodeid,
      paymentStatusId: paymentstatusid,
      deliveryMethodId: deliveryMethodId,
      paymentTransactionId: paymentTransactionId,
      orderStatus: orderstatusid,

      description: "",
      orderWorkflowStatus: orderWorkflowStatus,
      subTotalAmt: this.context.getSubTotalPriceInCart(
        this.context.getPOSCartByName(this.context.selectedPOS)
      ),
      deliveryAmt: this.context.getDeliveryAmount(
        this.context.getPOSCartByName(this.context.selectedPOS)
      ),
      totalAmt: this.context.getTotalAmountWithTax(
        this.context.getPOSCartByName(this.context.selectedPOS),
        this.props.coupon.couponValue
      ),
      totalAmtPaid: totalAmtPaid,

      couponCode: this.props.coupon.couponCode,
      couponValue: this.props.coupon.couponValue,
      totalSavings: this.context.getTotalSavingsFromCart(
        this.context.getPOSCartByName(this.context.selectedPOS)
      ),

      taxPercentage:
        this.context.taxDetails.inclusiveOfTax == true
          ? 0
          : this.context.taxDetails.taxPercentage,
      taxAmount:
        this.context.taxDetails.inclusiveOfTax == true
          ? 0
          : this.context.getTaxAmount(
              this.context.getPOSCartByName(this.context.selectedPOS),
              this.props.coupon.couponValue
            ),

      customerOrderedItems: customerOrderedItems,
      customerOrderDeliveryAddresses: customerOrderDeliveryAddresses,
    };

    if (this.context.isOfflineMode == true) {
      this.context.AddOfflineOrders(str);
      this.PrintInvoiceOffline(str);
      this.context.ClearCartAndOrderDetails(this.context.selectedPOS);
      //this.props.coupon = "";
      this.props.StoreCouponDetails("", 0);
      this.HidePayByCashModal();
      this.HidePayByQrcodeModal();
      window.scrollTo(0, 0);
    } else {
      axios
        .post(process.env.REACT_APP_API + "Consumers/SubmitOrder", str)
        .then((response) => {
          if (response.status === 200) {
            this.context.SetLastBillAmount(response.data.totalAmt);
            this.context.storeOrderInformation(response.data);
            this.SendInvoiceEmail(response.data);
            // this.context.UpdateOrderStatus("Success");
            this.GetPosInvoiceHtml(response.data);
            this.context.ClearCartAndOrderDetails(this.context.selectedPOS);
            //this.props.coupon = "";
            this.props.StoreCouponDetails("", 0);
            this.HidePayByCashModal();
            this.HidePayByQrcodeModal();
            window.scrollTo(0, 0);
          } else {
            alert("Order Submit Failed. Please try again!");
            this.context.UpdateOrderStatus("Failed");
            this.HideLoadSpinner();
          }
        })
        .catch((error) => {
          alert("Order Submit Failed. Please try again!");
          this.context.UpdateOrderStatus("Failed");
          this.HideLoadSpinner();
        });
    }
  };

  SendInvoiceEmail = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify(this.context.orderData),
      body: JSON.stringify(data),
    };

    var v1 = process.env.REACT_APP_API + "Consumers/SendInvoiceEmail";
    fetch(
      process.env.REACT_APP_API + "Consumers/SendInvoiceEmail/" + "POS",
      requestOptions
    )
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        if (response.status === 200) {
          return;
        } else {
          throw new Error(response);
        }
      })
      .catch((error) => {
        console.error("SendInvoiceEmail:There was an error!", error);
      });
  };
  ProceedCompleteOrder = () => {
    this.setState({ validatedAndSubmitted: "true" }, this.ProceedToPay());
  };
  ProceedToPay = () => {
    if (this.state.paymentmode === "RazorPay") {
      this.CallRazorPay();
    }
  };
  StoreOrderInformationInContext = () => {
    this.context.storeOrderInformation({
      name: this.state.firstName,
      mobileNo: this.state.mobileNo,
      emailId: this.state.emailId,
      address: this.state.address,
      country: this.state.country,
      state: this.state.state,
      city: this.state.city,
      pinCode: this.state.pinCode,
      paymentMode: this.state.paymentmode,
    });
  };

  ValidateCustomerDetails = () => {
    if (
      // this.state.orderTypeTakeAway == true ||
      this.state.orderTypeHomeDelivery == true
    ) {
      if (this.state.firstName == "" || this.state.mobileNo == "") {
        Swal.fire({
          title: "warning!",
          text: "Customer Name and Mobile Number are mandatory!",
          // confirmButtonText: "yes",
          timer: 1500,
        }).then((result) => {});
        return false;
      }
    }
    return true;
  };

  OnCOD = () => {
    //if (this.ValidateCustomerDetails() == false) return;
    // this.setState({ paymentmode: 1 }, () => {
    //   this.StoreOrderInDB("");
    // });
    this.ShowCodModal();
  };

  handlePayByCash = (event) => {
    if (this.ValidateCustomerDetails() == false) return;
    this.ShowPayByCashModal();
  };
  handlePayViaQrcode = (event) => {
    if (this.ValidateCustomerDetails() == false) return;
    this.ShowPayByQrCodeModal();
  };
  handlePayBySwipingMachine = (event) => {
    if (this.ValidateCustomerDetails() == false) return;
    this.ShowPayCardSwipingMachineModal();
  };

  handleRazorPaySubmit = (event) => {
    if (this.ValidateCustomerDetails() == false) return;
    this.setState({ paymentmode: 2 }, () => {
      this.CallRazorPay();
    });
    // const form = event.currentTarget;
    // event.preventDefault();

    // if (form.checkValidity() === false) {
    //   event.stopPropagation();
    // } else {
    //   this.setState({ paymentmode: 2 }, () => {
    //     this.CallRazorPay();
    //   });
    // }
    // this.setState({ validated: true });
  };

  OnPayWithInvoice = () => {
    if (this.ValidateCustomerDetails() == false) return;
    this.setState({ paymentmode: 6 }, () => {
      this.StoreOrderInDB("");
    });
  };

  updateCustomerDetails = (customerDetail) => {
    if (customerDetail != undefined) {
      this.setState({ firstName: customerDetail.firstName });
      this.setState({ mobileNo: customerDetail.mobileNo });
      this.setState({ emailId: customerDetail.emailId });
      this.setState({ address: customerDetail.address });
    }
  };
  OnCodPaid = (customerDetail) => {
    this.updateCustomerDetails(customerDetail);
    this.setState({ paymentmode: 1 }, () => {
      this.StoreOrderInDB("");
    });
  };
  OnCashPaid = (customerDetail) => {
    this.updateCustomerDetails(customerDetail);
    this.setState({ paymentmode: 3 }, () => {
      this.StoreOrderInDB("");
    });
  };

  OnQrcodePaid = (customerDetail) => {
    this.updateCustomerDetails(customerDetail);
    this.setState({ paymentmode: 4 }, () => {
      this.StoreOrderInDB("");
    });
  };

  OnCardSwipingMachinePaid = (customerDetail) => {
    this.updateCustomerDetails(customerDetail);
    this.setState({ paymentmode: 5 }, () => {
      this.StoreOrderInDB("");
    });
  };

  ShowCodModal = () => {
    this.setState({ showCodModal: true });
  };
  HideCodModal = () => {
    this.setState({ showCodModal: false });
  };
  ShowPayByCashModal = () => {
    this.setState({ showPayByCashModal: true });
  };
  HidePayByCashModal = () => {
    this.setState({ showPayByCashModal: false });
  };
  ShowPayByQrCodeModal = () => {
    this.setState({ showPayByQrCodeModal: true });
  };
  HidePayByQrcodeModal = () => {
    this.setState({ showPayByQrCodeModal: false });
  };
  ShowPayCardSwipingMachineModal = () => {
    this.setState({ showPayByCardSwipingMachineModal: true });
  };
  HidePayByCardSwipingMachineModal = () => {
    this.setState({ showPayByCardSwipingMachineModal: false });
  };
  OnCancelSale = () => {
    Swal.fire({
      title: "Are you sure wanted to Cancel the Sale?",
      showDenyButton: true,
      confirmButtonText: "yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.context.ClearCartAndOrderDetails(this.context.selectedPOS);
        window.scrollTo(0, 0);
      }
    });
  };

  _handleNVEvent = (event) => {
    // if (event.key == "F8" || event.key == "Enter")
    //   this.setState({ showPayByCashModal: true });
    if (event.key == "F9") this.setState({ showPayByCashModal: true });
  };
  componentDidMount() {
    if (this.props.quickPayEventSent == true)
      this.setState({ quickPayEvent: true });

    if (window.innerWidth >= 1100) {
      ReactDOM.findDOMNode(this).addEventListener(
        "keydown",
        this._handleNVEvent
      );
      ReactDOM.findDOMNode(this).addEventListener("keyup", this._handleNVEvent);
    }
    window.scrollTo(0, 0);
  }

  render() {
    if (this.context.OrderStatus !== "") {
      return <Navigate to="/CompleteOrder" />;
    }
    if (this.state.quickPayEvent == true) {
      if (this.props.quickPayEventKey == "F8") {
        this.state.quickPayEvent = false; //must
        this.props.SetQuickPayEventFalse();
        this.setState({ quickPayEvent: false }, () => {
          console.log("f8 on POS");
          this.OnCashPaid();
        });
      } else if (this.props.quickPayEventKey == "F9") {
        this.state.quickPayEvent = false; //must
        this.props.SetQuickPayEventFalse();
        this.setState({ quickPayEvent: false });
        this.OnQrcodePaid();
      }
    }

    return (
      <>
        {this.state.showCodModal == true ? (
          <PaymentTypeModal
            OnCodPaid={this.OnCodPaid}
            onHide={this.HideCodModal}
            coupon={this.props.coupon}
            show={this.state.showCodModal}
            paymentType="COD"
          ></PaymentTypeModal>
        ) : (
          ""
        )}
        {this.state.showPayByCashModal == true ? (
          <PaymentTypeModal
            OnCashPaid={this.OnCashPaid}
            onHide={this.HidePayByCashModal}
            coupon={this.props.coupon}
            show={this.state.showPayByCashModal}
            paymentType="CASH"
          ></PaymentTypeModal>
        ) : (
          ""
        )}
        {this.state.showPayByQrCodeModal == true ? (
          <PaymentTypeModal
            OnQrcodePaid={this.OnQrcodePaid}
            onHide={this.HidePayByQrcodeModal}
            coupon={this.props.coupon}
            show={this.state.showPayByQrCodeModal}
            paymentType="QRCODE"
          ></PaymentTypeModal>
        ) : (
          ""
        )}
        {this.state.showPayByCardSwipingMachineModal == true ? (
          <PaymentTypeModal
            OnCardSwipingMachineModalPaid={this.OnCardSwipingMachinePaid}
            onHide={this.HidePayByCardSwipingMachineModal}
            coupon={this.props.coupon}
            show={this.state.showPayByCardSwipingMachineModal}
            paymentType="CARD"
          ></PaymentTypeModal>
        ) : (
          ""
        )}

        {window.innerWidth > 1100 ? (
          <>
            <div
              className="w-100 m-0 p-0 border-0"
              style={{ backgroundColor: this.context.themeBorderColor }}
            >
              <Row className=" m-0 p-0 border-0 ">
                <Col>
                  <table className="w-100 ">
                    {this.context.OrderStatus == "Success" ? (
                      ""
                    ) : (
                      <>
                        {this.context.OrderStatus != "Success" &&
                        this.context.getTotalQtyInCart(
                          this.context.getPOSCartByName(
                            this.context.selectedPOS
                          )
                        ) > 0 ? (
                          <>
                            {this.state.orderTypeHomeDelivery ? (
                              <>
                                <tr className="alignverticalmiddle">
                                  <td className="col-md-3 "></td>
                                  <td className="col-md-3 "></td>
                                  <td className="col-md-3 text-center p-2">
                                    <Button
                                      variant="primary"
                                      className="  text-dark w-100 cardimage-round btn3d-success"
                                      style={{ height: 50 }}
                                      // style={{ height: 100 }}
                                      onClick={this.OnCOD}
                                    >
                                      <b>COD</b>
                                    </Button>
                                  </td>

                                  <td className="col-md-3 text-end">
                                    <Button
                                      className="btn3d-danger "
                                      type="submit"
                                      size="sm"
                                      onClick={this.OnCancelSale}
                                    >
                                      Cancel
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <></>
                            )}

                            {this.state.orderTypeOrderDelivered ||
                            this.state.orderTypeTakeAway ? (
                              <>
                                <tr className="verticalaligncentertd ">
                                  {this.state.orderTypeTakeAway ? (
                                    <td
                                      // width="20%"
                                      className=" text-center verticalaligncentertd"
                                    >
                                      <Button
                                        variant="primary"
                                        size="lg"
                                        className="  text-dark cardimage-round btn3d-success verticalaligncentertd"
                                        style={{ height: 50 }}
                                        onClick={this.OnCOD}
                                      >
                                        <b>COD</b>
                                      </Button>
                                    </td>
                                  ) : (
                                    <></>
                                  )}
                                  <td className=" text-center align-top">
                                    <Button
                                      variant="primary"
                                      // size="lg"
                                      style={{ height: 50 }}
                                      className="  text-dark w-100 cardimage-round btn3d-green"
                                      onClick={this.handlePayByCash}
                                    >
                                      <i class="bi bi-cash"></i>
                                      &nbsp;&nbsp;<b>CASH (F8)</b>
                                    </Button>
                                  </td>
                                  <td className=" text-center p-0">
                                    <Button
                                      variant="primary"
                                      // size="lg"
                                      className=" text-dark  w-100 cardimage-round btn3d-green"
                                      style={{ height: 50 }}
                                      onClick={this.handlePayViaQrcode}
                                    >
                                      <i class="bi bi-qr-code"></i>
                                      &nbsp;&nbsp;<b>QR (F9)</b>
                                    </Button>
                                  </td>
                                  <td className=" text-center p-0">
                                    <Button
                                      variant="primary"
                                      // size="lg"
                                      style={{ height: 50 }}
                                      className="   text-dark w-100 cardimage-round btn3d-green"
                                      onClick={this.handlePayBySwipingMachine}
                                      // style={{ height: 100 }}
                                    >
                                      <i class="bi bi-credit-card"></i>
                                      &nbsp;&nbsp;<b>Card </b>
                                    </Button>
                                  </td>
                                  {/* <td width="20%" className=" text-center p-2">
                                  <Button
                                    variant="primary"
                                    className=" w-100 cardimage-round btn3d-primary"
                                    //to="/CompleteOrder"
                                    // type="submit"
                                    style={{ height: 100 }}
                                    onClick={this.handleRazorPaySubmit}
                                  >
                                    <h2>
                                      <i class="bi bi-card-list"></i>
                                    </h2>
                                    <h6>
                                      <b>RAZORPAY</b>
                                    </h6>
                                  </Button>
                                </td> */}
                                  <td className=" text-end p-0">
                                    <Button
                                      className="btn3d-danger "
                                      type="submit"
                                      // style={{ height: 50 }}
                                      onClick={this.OnCancelSale}
                                    >
                                      Cancel
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </table>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <>
            <div className="w-100 m-0 p-0">
              <Table className="w-100 p-0 m-0 border-0" size="sm">
                <tbody>
                  {this.context.OrderStatus == "Success" ? (
                    ""
                  ) : (
                    <>
                      {this.context.OrderStatus != "Success" &&
                      this.context.getTotalQtyInCart(
                        this.context.getPOSCartByName(this.context.selectedPOS)
                      ) > 0 ? (
                        <>
                          {this.state.orderTypeHomeDelivery ? (
                            <>
                              <tr className="w-100 p-0 m-0 border-0">
                                <td className="text-center border-0">
                                  <Button
                                    variant="primary"
                                    className="  text-white cardimage-round btn3d-primary "
                                    onClick={this.OnCOD}
                                  >
                                    COD
                                  </Button>
                                </td>
                                <td className="text-end border-0">
                                  <Button
                                    className="btn3d-danger"
                                    type="submit"
                                    onClick={this.OnCancelSale}
                                  >
                                    Cancel
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {this.state.orderTypeOrderDelivered ||
                          this.state.orderTypeTakeAway ? (
                            <>
                              <tr className="text-center border-0 p-0 m-0">
                                {this.state.orderTypeTakeAway ? (
                                  <td className="text-center  p-0 m-0 border-0">
                                    <Button
                                      variant="primary"
                                      className="text-white cardimage-round btn3d-primary"
                                      onClick={this.OnCOD}
                                    >
                                      <b>COD</b>
                                    </Button>
                                  </td>
                                ) : (
                                  <></>
                                )}
                                <td className="text-center  p-0 m-0 border-0">
                                  <Button
                                    variant="primary"
                                    className="  text-white cardimage-round btn3d-primary"
                                    onClick={this.handlePayByCash}
                                  >
                                    <b>CASH</b>
                                  </Button>
                                </td>
                                <td className="text-center  p-0 m-0 border-0">
                                  <Button
                                    variant="primary"
                                    className=" text-white cardimage-round btn3d-primary"
                                    onClick={this.handlePayViaQrcode}
                                  >
                                    <b>QR</b>
                                  </Button>
                                </td>
                                <td className="text-center  p-0 m-0 border-0">
                                  <Button
                                    variant="primary"
                                    className="text-white cardimage-round btn3d-primary"
                                    onClick={
                                      this.ShowPayCardSwipingMachineModal
                                    }
                                  >
                                    <b>Card </b>
                                  </Button>
                                </td>
                                <td className=" text-end   p-0 m-0 border-0">
                                  <Button
                                    className="btn3d-danger"
                                    type="submit"
                                    onClick={this.OnCancelSale}
                                  >
                                    X
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </>
    );
  }
}

export class PaymentTypeModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      customerDetail: {
        firstName: "",
        emailId: "",
        mobileNo: "",
        address: "",
      },
      tmobileNo: "",
      amountReceived: "",
      amountToReturn: "",
      reload: false,
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "amountReceived":
        this.setState({ amountReceived: value });
        this.setState({
          amountToReturn:
            this.context.getTotalAmountWithTax(
              this.context.getPOSCartByName(this.context.selectedPOS),
              this.props.coupon.couponValue
            ) - value,
        });
        break;
      case "firstName":
        this.state.customerDetail.firstName = value;
        break;
      case "tmobileNo":
        this.setState({ tmobileNo: value });
        return;
      case "mobileNo":
        if (isNaN(Number(value)) == false)
          this.state.customerDetail.mobileNo = value;
        break;
      case "emailId":
        this.state.customerDetail.emailId = value;
        break;
      case "address":
        this.state.customerDetail.address = value;
        break;
    }
    this.setState({ customerDetail: this.state.customerDetail });
  };

  ValidateCustomerDetails = () => {
    if (
      this.state.customerDetail.firstName == "" ||
      this.state.customerDetail.mobileNo == ""
    ) {
      Swal.fire({
        title: "warning!",
        text: "Customer Name and Mobile Number are mandatory!",
        // confirmButtonText: "yes",
        timer: 1500,
      }).then((result) => {});
      return false;
    }
    return true;
  };

  ProcessPayment = () => {
    if (this.props.paymentType == "COD") {
      if (this.ValidateCustomerDetails() == true)
        this.props.OnCodPaid(this.state.customerDetail);
    }
    if (this.props.paymentType == "CASH")
      this.props.OnCashPaid(this.state.customerDetail);
    if (this.props.paymentType == "QRCODE")
      this.props.OnQrcodePaid(this.state.customerDetail);
    if (this.props.paymentType == "CARD")
      this.props.OnCardSwipingMachineModalPaid(this.state.customerDetail);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.ProcessPayment();
  };

  _handleNVEvent = (event) => {
    if (event.key == "Enter") {
      event.stopPropagation();
      this.ProcessPayment();
    }
  };
  AmtToReturn = () => {
    if (this.state.amountReceived == 0) return 0;
    var res = Math.round(this.state.amountToReturn * -1, 0);
    return res;
  };

  GetCustomerAccountByMobileNo = (e) => {
    if (
      this.state.tmobileNo == "" ||
      (this.state.tmobileNo != "" && this.state.tmobileNo.length != 10)
    ) {
      alert("Invalid MobileNo. Enter a valid Customer Mobile number and try!");
      return;
    }
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetCustomerAccountByMobileNo/" +
        this.context.storeId +
        "/" +
        this.state.tmobileNo
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert("Mobile Number Not Found!");
        } else {
          this.setState({ customerDetail: data }, () => {
            this.setState({ reload: true }, () => {
              this.setState({ reload: false });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this._handleNVEvent, false);
    var ctrl = document.getElementById("amountReceived");
    if (ctrl) ctrl.focus();
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="payByCash"
        >
          <Modal.Header closeButton className="bg-warning">
            <Modal.Title id="contained-modal-title-vcenter">
              {this.props.paymentType == "COD" ? <>COD</> : <></>}
              {this.props.paymentType == "CASH" ? <>Pay by Cash</> : <></>}
              {this.props.paymentType == "QRCODE" ? <>Pay by QRCODE</> : <></>}
              {this.props.paymentType == "CARD" ? <>Pay by CARD</> : <></>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid ">
            {this.props.paymentType == "CASH" ? (
              <>
                <div className="border p-3 bg-light">
                  <tr width={"100%"}>
                    <td width={"50%"}>
                      <Form.Label>
                        <h4>Bill Amount:</h4>
                      </Form.Label>
                    </td>
                    <td width={"50%"}>
                      <Form.Label>
                        <h4>
                          &nbsp;
                          {Math.round(
                            this.context.getTotalAmountWithTax(
                              this.context.getPOSCartByName(
                                this.context.selectedPOS
                              ),
                              this.props.coupon.couponValue
                            ),
                            0
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </h4>
                      </Form.Label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Label column sm="6">
                        <h4>Received:</h4>
                      </Form.Label>
                    </td>
                    <td>
                      <Form.Control
                        sm="6"
                        type="number"
                        size="lg"
                        id="amountReceived"
                        name="amountReceived"
                        value={this.state.amountReceived}
                        placeholder=""
                        autoComplete="new-password"
                        keyboard={true}
                        onChange={(e) => this.handleInputChange(e)}
                        style={{ fontSize: "26px" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Label column sm="6">
                        <h4>Amount to Return:</h4>
                      </Form.Label>
                    </td>
                    <td>
                      <Form.Label className="verticalaligncentertd">
                        <h4>
                          &nbsp;
                          {this.AmtToReturn().toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </h4>
                      </Form.Label>
                    </td>
                  </tr>
                </div>
              </>
            ) : (
              <></>
            )}
            {this.props.paymentType == "QRCODE" ? (
              <>
                <div className="border p-3 bg-light">
                  <table className="w-100">
                    <tr className="text-center align-middle">
                      <td className="col-md-12 text-center">
                        <h4>
                          <b>
                            Bill Total Amount: &nbsp;
                            {Math.round(
                              this.context.getTotalAmountWithTax(
                                this.context.getPOSCartByName(
                                  this.context.selectedPOS
                                ),
                                this.props.coupon.couponValue
                              ),
                              0
                            )}
                          </b>
                        </h4>
                      </td>
                    </tr>
                    <tr className="text-center align-middle">
                      <td className="col-md-12">
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/qrcode.png" +
                            this.context.store.storageSasToken
                          }
                          className=" mx-auto"
                          alt="..."
                          style={{ width: "5cm" }}
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </>
            ) : (
              <></>
            )}
            {this.props.paymentType == "CARD" ? (
              <>
                <div className="border p-3 bg-light">
                  <table className="w-100">
                    <tr className="text-center align-middle">
                      <td className="col-md-12 text-center">
                        <h4>
                          <b>
                            Bill Total Amount: &nbsp;
                            {Math.round(
                              this.context.getTotalAmountWithTax(
                                this.context.getPOSCartByName(
                                  this.context.selectedPOS
                                ),
                                this.props.coupon.couponValue
                              ),
                              0
                            )}
                          </b>
                        </h4>
                      </td>
                    </tr>
                  </table>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="border p-3">
              <h4>Customer Details</h4>
              <Table className="w-100  m-0 border-0 bg-white text-dark">
                <tbody className="w-100 border-0 p-0 m-0">
                  <tr className="w-100 border-0 p-0 m-0">
                    <td className="w-100 border-0 ">
                      <Stack direction="horizontal">
                        <FloatingLabel controlId="" label="Mobile Number">
                          <Form.Control
                            type="number"
                            // className="w-100"
                            name="tmobileNo"
                            maxLength={10}
                            value={this.state.tmobileNo}
                            required
                            placeholder="Mobile Number"
                            // hidden={
                            //   !(
                            //     this.state.customerDetail.mobileNo ==
                            //       undefined ||
                            //     this.state.customerDetail.mobileNo == null ||
                            //     this.state.customerDetail.mobileNo == ""
                            //   )
                            // }
                            autoComplete="new-password"
                            onChange={(e) => this.handleInputChange(e)}
                            style={{ fontSize: "32px" }}
                          />
                        </FloatingLabel>
                        <Button
                          onClick={(e) => this.GetCustomerAccountByMobileNo(e)}
                        >
                          Get/Add Customer
                        </Button>{" "}
                        &nbsp;
                      </Stack>
                    </td>
                  </tr>
                  {/* <tr className="w-100 border-0 p-0 m-0">
                    <td className="w-100 border-0 ">
                      <FloatingLabel controlId="" label="Mobile Number">
                        <Form.Control
                          type="text"
                          // className="w-100"
                          name="mobileNo"
                          maxLength={10}
                          value={this.state.customerDetail.mobileNo}
                          required
                          placeholder="Mobile Number"
                          autoComplete="new-password"
                          readOnly={!this.state.customerDetail.mobileNo}
                          onChange={(e) => this.handleInputChange(e)}
                          style={{ fontSize: "22px" }}
                        />
                      </FloatingLabel>
                    </td>
                  </tr> */}
                  <tr className="w-100 border-0 p-0 m-0">
                    <td className="w-100 border-0 ">
                      <FloatingLabel controlId="" label="Name">
                        <Form.Control
                          type="text"
                          className="w-100"
                          name="firstName"
                          value={
                            this.state.customerDetail.firstName == null
                              ? ""
                              : this.state.customerDetail.firstName
                          }
                          required
                          readOnly={!this.state.customerDetail.mobileNo}
                          placeholder="Name"
                          autoComplete="new-password"
                          onChange={(e) => this.handleInputChange(e)}
                          style={{ fontSize: "24px" }}
                        />
                      </FloatingLabel>
                    </td>
                  </tr>

                  <tr className="w-100 border-0 p-0 m-0">
                    <td className="w-100 border-0 ">
                      <FloatingLabel controlId="" label="Emaild-Id">
                        <Form.Control
                          type="email"
                          className="w-100"
                          name="emailId"
                          value={
                            this.state.customerDetail.emailId == null
                              ? ""
                              : this.state.customerDetail.emailId
                          }
                          required
                          readOnly={!this.state.customerDetail.mobileNo}
                          placeholder="Email-id"
                          autoComplete="new-password"
                          onChange={(e) => this.handleInputChange(e)}
                          style={{ fontSize: "24px" }}
                        />
                      </FloatingLabel>
                    </td>
                  </tr>
                  <tr className="w-100 border-0 p-0 m-0">
                    <td className="w-100 border-0 ">
                      <FloatingLabel controlId="" label="Address">
                        <Form.Control
                          type="text"
                          className="w-100"
                          name="address"
                          value={
                            this.state.customerDetail.address == null
                              ? ""
                              : this.state.customerDetail.address
                          }
                          // required
                          placeholder="Address"
                          autoComplete="new-password"
                          readOnly={!this.state.customerDetail.mobileNo}
                          onChange={(e) => this.handleInputChange(e)}
                          style={{ fontSize: "24px" }}
                        />
                      </FloatingLabel>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <Button variant="primary" size="lg" onClick={this.handleSubmit}>
              {this.props.paymentType == "COD" ? <>Ok</> : <>Paid</>}
            </Button>

            <Button variant="danger" size="lg" onClick={this.props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
