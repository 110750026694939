import React, { Component } from "react";
import CartContext from "./CartContext";
import { FiRefreshCcw } from "react-icons/fi";
import { Navigate } from "react-router-dom";
import axios from "axios";
import FavList from "./FavList";
import kotItemTemplate from "./kotItem.txt";
import kotTableTemplate from "./kotTable.txt";
import CheckoutTO from "./CheckoutTO";
import IncDecCounter from "./IncDecCounterControl";
import SearchViewTO from "./SearchViewTO.js";
import { OrderSummaryTO } from "./OrderSummaryTO.js";

import {
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
  Form,
  Navbar,
  Nav,
  Row,
  Col,
} from "react-bootstrap";

const WAIT_INTERVAL = 500;

export default class TableOrder extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      showProductListByCategory: false,
      updateCartView: "true",
      havePermissions: false,
      myCart: [],
      selectedTableId: "",
      coupon: { couponCode: "", couponValue: 0 },
      generatedInvoiceOrder: "",
      saleDone: false,
      reload: false,
    };
  }

  StoreCouponDetails = (couponCode, couponValue) => {
    this.setState({
      coupon: { couponCode: couponCode, couponValue: couponValue },
    });
  };

  Refresh = () => {
    this.setState({ myCart: [] }, () => {
      this.GetRestaturantTableOrderByTableId(this.state.selectedTableId);
    });
  };

  GetRestaturantTableOrderByTableId = (tableId) => {
    this.setState({ myCart: [] }, () => {
      fetch(
        process.env.REACT_APP_API +
          "Consumers/GetRestaturantTableOrderByTableId/" +
          tableId
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 400) {
          } else {
            this.setState({ myCart: data });
          }
        });
    });
  };

  AddRestaurantTableOrderItem = (item) => {
    var orderItem = {
      tableId: this.state.selectedTableId,
      productId: item.prod.id,
      fileName: item.prod.imgFileName,
      productName: item.prod.name,
      mrp: item.prod.mrp,
      discount: item.prod.discount,
      qty: item.qty,
      orderStatusId: "1",
      seqNo: this.state.myCart.length + 1,
      // created: new Date().toLocaleString(),
    };
    axios
      .post(
        process.env.REACT_APP_API + "Consumers/AddRestaurantTableOrderItem",
        orderItem
      )
      .then((response) => {
        if (response.status === 200) {
          this.GetRestaturantTableOrderByTableId(this.state.selectedTableId);
        } else if (response.status === 204) {
          alert("This Product Out-Of-Stock. Check with your Inventory Team!");
        } else {
          alert("Order Submit Failed. Please try again!");
        }
      })
      .catch((error) => {
        console.error("AddRestaurantTableOrderItem:There was an error!", error);
      });
  };
  UpdateRestaurantTableOrderItem = (item) => {
    axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/UpdateRestaurantTableOrderedItem",
        item
      )
      .then((response) => {
        if (response.status === 200) {
          this.GetRestaturantTableOrderByTableId(this.state.selectedTableId);
        } else {
          alert("UpdateRestaurantTableOrderItem Failed. Please try again!");
        }
      })
      .catch((error) => {
        console.error(
          "UpdateRestaurantTableOrderItem:There was an error!",
          error
        );
      });
  };
  DeleteRestaurantTableOrderedItem = (id) => {
    axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/DeleteRestaurantTableOrderedItem/" +
          id
      )
      .then((response) => {
        if (response.status === 200) {
          this.GetRestaturantTableOrderByTableId(this.state.selectedTableId);
        } else {
          alert("delete Failed. Try again!");
        }
      })
      .catch((error) => {
        console.error("delete Failed. Try again!:There was an error!", error);
      });
  };

  UpdateCartView = () => {
    this.setState({ myCart: [] }, () => {
      window.scrollTo(0, 0);
    });
  };

  GetOrderByOrderId = (invoiceNumber) => {
    axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetOrderByOrderId/" +
          this.context.storeId +
          "/" +
          invoiceNumber
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ generatedInvoiceOrder: response.data }, () => {
            this.setState({ reload: true }, () => {
              this.setState({ reload: false });
            });
          });
        }
      })
      .catch((error) => {
        console.error("delete Failed. Try again!:There was an error!", error);
      });
  };

  StoreGeneratedInvoice = (invoiceOrder) => {
    this.setState({ generatedInvoiceOrder: invoiceOrder });
  };

  SaleDone = () => {
    this.setState({ saleDone: true });
    this.context.GetRestaturantTables();
  };

  componentDidMount = () => {
    this.setState({ selectedTableId: this.context.selectedTable.id }, () => {
      this.GetRestaturantTableOrderByTableId(this.context.selectedTable.id);
      console.log("invoicenumber=" + this.context.selectedTable.invoiceNumber);
      if (
        this.context.selectedTable.invoiceNumber != "" &&
        this.context.selectedTable.invoiceNumber != undefined
      ) {
        this.GetOrderByOrderId(this.context.selectedTable.invoiceNumber);
      }
    });
  };
  componentDidUpdate = () => {
    this.context.SetQtyInCartModified(false);
  };

  render() {
    if (this.state.saleDone == true) return <Navigate to="/TableOrders" />;
    if (this.context.storeId == "") return <Navigate to="/home" />;
    return (
      <>
        {this.state.selectedTableId == "" ? (
          ""
        ) : (
          <>
            {window.innerWidth > 1100 ? (
              <>
                <div className="m-0 p-0 border border-dark">
                  <div
                    className=" text-center  m-0 p-0 border border-dark"
                    style={{
                      backgroundColor: this.context.themeBorderColor,
                      color: "white",
                    }}
                  >
                    <h4>
                      <b>
                        TABLE -{" "}
                        {
                          this.context.getTableById(
                            this.context.selectedTable.id
                          ).name
                        }
                      </b>
                      <small className="" style={{ color: "lightgray" }}>
                        &nbsp;&nbsp;[Waiter:{" "}
                        <i>{this.context.selectedTable.waiterName}] </i>
                      </small>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        size="sm"
                        className="CornersRounded"
                        onClick={this.Refresh}
                      >
                        &nbsp;&nbsp;<FiRefreshCcw></FiRefreshCcw> &nbsp;&nbsp;
                        Refresh&nbsp;&nbsp;
                      </Button>
                    </h4>
                  </div>
                  <Table width="100% m-0 p-0 border border-dark">
                    <tbody>
                      <tr className="border p-0 m-0">
                        {this.context.hideFav == true ? (
                          <>
                            <td
                              className=" m-0 p-0"
                              style={{
                                width: "50%",
                                pointerEvents:
                                  this.state.generatedInvoiceOrder != ""
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <SearchViewTO
                                addToCart={this.AddRestaurantTableOrderItem}
                                AddRestaurantTableOrderItem={
                                  this.AddRestaurantTableOrderItem
                                }
                              ></SearchViewTO>
                            </td>
                          </>
                        ) : (
                          <>
                            <td
                              className="p-0 m-0"
                              hidden={this.context.hideFav}
                              style={{
                                width: "50%",
                                pointerEvents:
                                  this.state.generatedInvoiceOrder != ""
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <FavList
                                AddRestaurantTableOrderItem={
                                  this.AddRestaurantTableOrderItem
                                }
                              ></FavList>
                            </td>
                          </>
                        )}

                        <td
                          className="border p-0 m-0"
                          style={{
                            backgroundColor: "#EBF0F9",
                            width: this.context.hideFav == true ? "50%" : "40%",
                          }}
                        >
                          {this.context.qtyInCartModified == false ? (
                            <>
                              <div
                                className="my-0 fixedTableHightOrderQuque"
                                style={{
                                  minHeight: window.innerHeight - 100 + "px",
                                  maxHeight: window.innerHeight - 100 + "px",
                                  pointerEvents:
                                    this.state.generatedInvoiceOrder != ""
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                <CartViewPOS
                                  updateCartView={this.UpdateCartView}
                                  myCart={this.state.myCart}
                                  selectedTableId={this.state.selectedTableId}
                                  addToCart={this.AddRestaurantTableOrderItem}
                                  deleteFromCart={
                                    this.DeleteRestaurantTableOrderedItem
                                  }
                                  UpdateOrderItem={
                                    this.UpdateRestaurantTableOrderItem
                                  }
                                ></CartViewPOS>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.context.getTotalQtyInCartTable(
                            this.state.myCart
                          ) > 0 ? (
                            <>
                              <div
                                className="my-2"
                                style={{
                                  pointerEvents:
                                    this.state.generatedInvoiceOrder != ""
                                      ? "none"
                                      : "auto",
                                }}
                              ></div>
                              <div className="my-2">
                                {this.state.reload == false ? (
                                  <>
                                    <BottomSummaryTO
                                      StoreCouponDetails={
                                        this.StoreCouponDetails
                                      }
                                      myCart={this.state.myCart}
                                      coupon={this.state.coupon}
                                      updateCartView={this.UpdateCartView}
                                      tableName={
                                        this.context.getTableById(
                                          this.state.selectedTableId
                                        ).name
                                      }
                                      generatedInvoiceOrder={
                                        this.state.generatedInvoiceOrder
                                      }
                                      StoreGeneratedInvoice={
                                        this.StoreGeneratedInvoice
                                      }
                                      SaleDone={this.SaleDone}
                                    ></BottomSummaryTO>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <>
                <div className="m-0 p-0 w-100">
                  <div
                    className=" text-center py-0 m-0 p-0 "
                    style={{
                      backgroundColor: "black",
                      color: "white",
                    }}
                  >
                    <h5>
                      <b>
                        TABLE -{" "}
                        {
                          this.context.getTableById(
                            this.context.selectedTable.id
                          ).name
                        }
                      </b>
                      <small className="" style={{ color: "lightgray" }}>
                        &nbsp;&nbsp;[Waiter:{" "}
                        {this.context.selectedTable.waiterName}]{" "}
                      </small>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        size="sm"
                        className="rounded-circle"
                        onClick={this.Refresh}
                        style={{ backgroundColor: "blue", color: "white" }}
                      >
                        <FiRefreshCcw size="20px"></FiRefreshCcw>
                      </Button>
                    </h5>
                  </div>
                  <div className="w-100 m-0 p-0 ">
                    <Table className="w-100 m-0 p-0 ">
                      <tbody>
                        <tr className="">
                          <td
                            className="p-0 m-0"
                            style={{
                              pointerEvents:
                                this.state.generatedInvoiceOrder != ""
                                  ? "none"
                                  : "auto",
                            }}
                          >
                            <SearchViewTO
                              addToCart={this.AddRestaurantTableOrderItem}
                              AddRestaurantTableOrderItem={
                                this.AddRestaurantTableOrderItem
                              }
                            ></SearchViewTO>
                          </td>
                        </tr>
                        <tr>
                          <td className=" ">
                            {this.context.qtyInCartModified == false ? (
                              <>
                                <div
                                  className="my-0"
                                  style={{
                                    pointerEvents:
                                      this.state.generatedInvoiceOrder != ""
                                        ? "none"
                                        : "auto",
                                  }}
                                >
                                  <CartViewPOS
                                    updateCartView={this.UpdateCartView}
                                    myCart={this.state.myCart}
                                    selectedTableId={this.state.selectedTableId}
                                    addToCart={this.AddRestaurantTableOrderItem}
                                    deleteFromCart={
                                      this.DeleteRestaurantTableOrderedItem
                                    }
                                    UpdateOrderItem={
                                      this.UpdateRestaurantTableOrderItem
                                    }
                                  ></CartViewPOS>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {this.context.getTotalQtyInCartTable(
                              this.state.myCart
                            ) > 0 ? (
                              <>
                                <BottomSummaryTO
                                  StoreCouponDetails={this.StoreCouponDetails}
                                  myCart={this.state.myCart}
                                  coupon={this.state.coupon}
                                  updateCartView={this.UpdateCartView}
                                  tableName={
                                    this.context.getTableById(
                                      this.state.selectedTableId
                                    ).name
                                  }
                                  generatedInvoiceOrder={
                                    this.state.generatedInvoiceOrder
                                  }
                                  StoreGeneratedInvoice={
                                    this.StoreGeneratedInvoice
                                  }
                                  SaleDone={this.SaleDone}
                                ></BottomSummaryTO>
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

class CartViewPOS extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      forUpdateValue: 1,
      showAddCustomItemModal: false,
      kotitemtemplate: "",
      kottabletemplate: "",
    };
  }

  //static forceUpdate = useForceUpdate();
  //create your forceUpdate hook
  forceUpdate = () => {
    this.setState({ forUpdateValue: 0 }, () => {
      this.setState({ forUpdateValue: 1 });
    });
  };

  ValidateProductInstockAvailablity = () => {
    var cart = this.context.myCart;
    cart.map((p, i) => {
      var prd = this.context.prodsAll.filter((f) => f.id == p.prod.id);
      if (prd.length == 1 && prd[0].inStockQty >= Number(p.qty)) {
        //ok
        console.log("prod in-stock" + prd[0].name);
      } else {
        //delete prod from cart
        this.context.deleteItemOnMyCart(p.prod.id);
      }
    });
  };

  ShowAddCustomItemModal = () => {
    this.setState({ showAddCustomItemModal: true });
  };
  HideAddCustomItemModal = () => {
    this.setState({ showAddCustomItemModal: false });
  };

  OnAddCustomItem = () => {
    this.ShowAddCustomItemModal();
  };

  CloseCartOffCanvas = () => {
    this.props.CloseCartOffCanvas();
  };
  OnOrderStatusChange = (e, cartItem) => {
    cartItem.orderStatusId = e.target.value;
    this.props.UpdateOrderItem(cartItem);
  };

  onTabkeKot = () => {
    if (this.props.myCart.length > 0) {
      var table = this.context.getTableById(this.props.selectedTableId);
      var text = this.state.kottabletemplate;
      text = text.replace("{{storename}}", this.context.store.name);
      text = text.replace("{{Date}}", this.context.GetInDateFormat(new Date()));
      text = text.replace("{{tablename}}", table.name);

      var items = "";
      var totqty = 0;
      this.props.myCart
        .filter((f) => f.orderStatusId == 1)
        .map((item, i) => {
          var itemhtml =
            "<tr><td><h3>{{no}}</h3></td> <td><h3>{{prodname}}</h3></td> <td><h3>{{qty}}</h3></td> </tr>";
          itemhtml = itemhtml.replace("{{no}}", i + 1);
          itemhtml = itemhtml.replace("{{prodname}}", item.productName);
          itemhtml = itemhtml.replace("{{qty}}", item.qty);

          totqty += item.qty;
          items += itemhtml;
        });
      text = text.replace("{{Items}}", items);
      text = text.replace("{{TotalQty}}", totqty);

      var printWindow = window.open("", "_blank");
      printWindow.document.write(text);
      printWindow.document.close();
      this.onPrintFinished(printWindow.print(), printWindow);
    }
  };

  onItemKot = (cartitem) => {
    var table = this.context.getTableById(cartitem.tableId);
    var text = this.state.kotitemtemplate;
    text = text.replace("{{storename}}", this.context.store.name);
    text = text.replace("{{Date}}", this.context.GetInDateFormat(new Date()));
    text = text.replace("{{tablename}}", table.name);
    text = text.replace("{{prodname}}", cartitem.productName);
    text = text.replace("{{prodqty}}", cartitem.qty);

    var printWindow = window.open("", "_blank");
    printWindow.document.write(text);
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };
  getOS = () => {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes("Mac") && "ontouchend" in document)
    )
      return "iOS";

    var i,
      os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], "i").test(uA)) return os[i];
  };

  onPrintFinished = (status, printWindow) => {
    var osvar = this.getOS();
    if (osvar == "Windows") printWindow.close();
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    fetch(kotItemTemplate)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ kotitemtemplate: text });
      });
    fetch(kotTableTemplate)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ kottabletemplate: text });
      });

    //this is not required for POS
    //this.ValidateProductInstockAvailablity();
    this.context.UpdateTableOrderStatus("");
  }

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.state.forUpdateValue == 0) return "";
    return (
      <>
        {this.state.showAddCustomItemModal == true ? (
          <AddCustomItem
            onHide={this.HideAddCustomItemModal}
            addToCart={this.props.addToCart}
          ></AddCustomItem>
        ) : (
          ""
        )}
        {window.innerWidth > 1100 ? (
          <>
            <div
              className="m-0 p-0 border fixedTableHightOrderQuque"
              style={{
                minHeight: window.innerHeight - 100 + "px",
                maxHeight: window.innerHeight - 100 + "px",
                backgroundColor: "white",
                color: "black",
              }}
            >
              <Table className="w-100 p-0 m-0">
                <tr
                  className="text-start  text-white my-0"
                  style={{ backgroundColor: "#1CA350", color: "white" }}
                >
                  <td className="col-md-6">
                    <div className=" text-start">
                      <h4>
                        <i class="bi bi-cart-check"></i>
                        &nbsp;<b>Orders</b>
                      </h4>
                    </div>
                  </td>

                  <td className="col-md-4 text-end ">
                    <Button
                      className="mx-2 bg-dark CornersRounded"
                      onClick={this.OnAddCustomItem}
                    >
                      &nbsp;&nbsp;+ Add Custom Item&nbsp;&nbsp;
                    </Button>
                  </td>
                  <td className="col-md-2 text-end">
                    <Button
                      className="CornersRounded bg-dark"
                      onClick={(e) => this.onTabkeKot()}
                    >
                      &nbsp;&nbsp;KOT&nbsp;&nbsp;
                    </Button>
                  </td>
                </tr>
              </Table>
              <Table
                className="borderless w-100  m-0 p-0  bg-white "
                responsive="md"
              >
                <thead>
                  <tr
                    className="text-center  text-dark m-0 p-0"
                    style={{
                      fontSize: "10pt",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    <th width="2%"></th>
                    <th width="8%"></th>
                    <th width="23%" className="text-start"></th>
                    <th width="8%">Price</th>
                    <th width="17%">Qty</th>
                    <th width="10%">S.Tot</th>
                    <th width="20%">Status</th>
                    <th width="5%"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.myCart
                    .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                    .map((cartitem, i) => (
                      <>
                        <tr className="text-center align-middle text-black ">
                          <td>{i + 1}</td>
                          {cartitem.fileName == undefined ||
                          cartitem.fileName == null ||
                          cartitem.fileName == "" ? (
                            <>
                              <td></td>
                            </>
                          ) : (
                            <>
                              <td className="">
                                <img
                                  src={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    cartitem.fileName +
                                    this.context.store.storageSasToken
                                  }
                                  className="cardprodimg-on-cartview mx-auto  rounded-circle"
                                  alt="..."
                                  style={{
                                    cursor: "pointer",
                                    width: "1cm",
                                  }}
                                />
                              </td>
                            </>
                          )}

                          <td className=" text-black text-start ">
                            <strong className="mx-1">
                              {cartitem.productName}
                            </strong>
                            {/* <Button
                              className="CornersRounded"
                              variant="danger"
                              size="sm"
                              onClick={(e) => this.onItemKot(cartitem)}
                            >
                              &nbsp;KOT&nbsp;
                            </Button> */}
                          </td>
                          <td className="">
                            {Number(cartitem.mrp).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </td>
                          {/* <td className="text-center ">{cartitem.discount}%</td> */}
                          <td className=" text-center">
                            <IncDecCounter
                              intvalue={cartitem.qty}
                              disabled={
                                cartitem.orderStatusId != 1 ? true : false
                              }
                              valueChangeCallback={(e) => {
                                cartitem.qty = e;
                                this.props.UpdateOrderItem(cartitem);
                              }}
                            ></IncDecCounter>
                          </td>
                          <td className=" text-black">
                            <strong>
                              {Number(
                                cartitem.qty *
                                  (cartitem.mrp -
                                    (cartitem.mrp * cartitem.discount) / 100)
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </strong>
                            {/* <br></br>
                            Savings:
                            {Number(
                              cartitem.qty *
                                ((cartitem.mrp * cartitem.discount) / 100)
                            )
                              
                              .toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )} */}
                          </td>
                          <td>
                            <Form.Select
                              value={cartitem.orderStatusId}
                              size="lg"
                              style={{
                                fontWeight: "bold",
                                color:
                                  cartitem.orderStatusId == 1
                                    ? "blue"
                                    : cartitem.orderStatusId == 2
                                    ? "red"
                                    : cartitem.orderStatusId == 3
                                    ? "green"
                                    : cartitem.orderStatusId == 4
                                    ? "black"
                                    : "",
                                //color: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnOrderStatusChange(e, cartitem)
                              }
                            >
                              {this.context.restaurantTableOrdereStatus.map(
                                (sta) => (
                                  <option value={sta.id}>
                                    {" "}
                                    {sta.orderStatus}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          </td>
                          <td className="">
                            <OverlayTrigger
                              overlay={<Tooltip>Delete!</Tooltip>}
                              popperConfig={{
                                modifiers: {
                                  preventOverflow: {
                                    enabled: false,
                                  },
                                },
                              }}
                            >
                              <Button
                                variant="outline-danger"
                                onClick={(e) => {
                                  if (
                                    window.confirm("Do you want to Delete?")
                                  ) {
                                    this.props.deleteFromCart(cartitem.id);
                                  }
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <>
            <div>
              <table className="w-100 ">
                <tbody>
                  <tr
                    className="text-start text-white "
                    style={{ backgroundColor: "darkgreen" }}
                  >
                    <td className="border-0">
                      <div className="text-white text-start py-2">
                        <h5>
                          <i class="bi bi-cart-check"></i>
                          &nbsp;<b>Orders</b>
                        </h5>
                      </div>
                    </td>
                    <td className="text-center border-0">
                      <Button
                        className="mx-2 border-white "
                        style={{ backgroundColor: "darkgreen" }}
                        onClick={this.OnAddCustomItem}
                      >
                        + Custom Item
                      </Button>
                    </td>
                    <td className="col-md-2 text-center">
                      <Button
                        className="CornersRounded"
                        variant="danger"
                        onClick={(e) => this.onTabkeKot()}
                      >
                        &nbsp;&nbsp;KOT&nbsp;&nbsp;
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Table
                className="w-100  m-0 p-0 border-dark bg-white"
                // responsive="md"
                size="sm"
              >
                <thead>
                  <tr
                    className="text-center bg-light text-dark"
                    style={{ fontSize: "12pt" }}
                  >
                    <th width="35%">Item</th>
                    {/* <th width="33%">Qty</th> */}
                    <th width="30%">Qty</th>
                    <th width="30%">Status</th>
                    <th width="5%"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.myCart
                    .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                    .map((cartitem, i) => (
                      <>
                        <tr className="text-center align-middle text-black">
                          <td className=" text-black text-start ">
                            {i + 1}.&nbsp;
                            {cartitem.productName}&nbsp;
                            {Number(
                              cartitem.mrp -
                                (cartitem.mrp * cartitem.discount) / 100
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                            {/* &nbsp;
                            <Button
                              className="CornersRounded"
                              variant="danger"
                              size="sm"
                              onClick={(e) => this.onItemKot(cartitem)}
                            >
                              &nbsp;KOT&nbsp;
                            </Button>{" "} */}
                          </td>
                          <td className=" text-center">
                            <IncDecCounter
                              disabled={
                                cartitem.orderStatusId != 1 ? true : false
                              }
                              intvalue={cartitem.qty}
                              valueChangeCallback={(e) => {
                                cartitem.qty = e;
                                this.props.UpdateOrderItem(cartitem);
                              }}
                            ></IncDecCounter>{" "}
                            {/* <strike>
                              {cartitem.mrp.toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </strike>{" "}
                            &nbsp;&nbsp; */}
                            {Number(
                              cartitem.qty *
                                (cartitem.mrp -
                                  (cartitem.mrp * cartitem.discount) / 100)
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </td>

                          <td className="">
                            <Form.Select
                              value={cartitem.orderStatusId}
                              size="lg"
                              className="my-2 fw-bold"
                              style={{
                                // backgroundColor: "yellow",
                                color:
                                  cartitem.orderStatusId == 1
                                    ? "blue"
                                    : cartitem.orderStatusId == 2
                                    ? "red"
                                    : cartitem.orderStatusId == 3
                                    ? "green"
                                    : cartitem.orderStatusId == 4
                                    ? "black"
                                    : "",
                              }}
                              required
                              onChange={(e) =>
                                this.OnOrderStatusChange(e, cartitem)
                              }
                            >
                              {this.context.restaurantTableOrdereStatus.map(
                                (sta) => (
                                  <option value={sta.id}>
                                    {" "}
                                    {sta.orderStatus}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          </td>
                          <td className="text-end">
                            <OverlayTrigger
                              overlay={<Tooltip>Delete!</Tooltip>}
                              popperConfig={{
                                modifiers: {
                                  preventOverflow: {
                                    enabled: false,
                                  },
                                },
                              }}
                            >
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                  if (
                                    window.confirm("Do you want to Delete?")
                                  ) {
                                    this.props.deleteFromCart(cartitem.id);
                                  }
                                }}
                              >
                                X
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </Table>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </>
        )}
      </>
    );
  }
}

export class AddCustomItem extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      price: "",
      discount: "",
      qty: "",
      sellingPrice: "",
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "productName":
        this.setState({ productName: value });
        break;
      case "price":
        this.setState({ price: value });
        break;
      case "discount":
        this.setState({ discount: value });
        break;
      case "qty":
        this.setState({ qty: value });
        break;
    }
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    this.props.addToCart({
      prod: {
        id: this.context.GetNewGuid(),
        name: this.state.productName,
        mrp: this.state.price,
        discount: this.state.discount,
      },
      qty: this.state.qty,
    });
    this.props.onHide();
  };
  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={true}
          dialogClassName="payByCash"
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Add Custom Item
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Product Name:</h2>
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="text"
                    size="lg"
                    name="productName"
                    value={this.state.productName}
                    required
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Price:</h2>
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="number"
                    size="lg"
                    name="price"
                    value={this.state.price}
                    required
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Discount(%):</h2>
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="number"
                    size="lg"
                    name="discount"
                    value={this.state.discount}
                    required
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Qty:</h2>
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="number"
                    size="lg"
                    name="qty"
                    value={this.state.qty}
                    required
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Selling Price:</h2>
                </Form.Label>
                <Col sm="6">
                  <h2>
                    {Number(
                      this.state.price * this.state.qty -
                        (this.state.price *
                          this.state.qty *
                          this.state.discount) /
                          100
                    ).toLocaleString(
                      this.context.storeSettings == ""
                        ? "en-IN"
                        : this.context.storeSettings.defaultLocale,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1,
                        style: "currency",
                        currency:
                          this.context.storeSettings == ""
                            ? "INR"
                            : this.context.storeSettings.defaultCurrency,
                      }
                    )}
                  </h2>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  {/* <Button variant="primary" size="lg" onClick={this.handleSubmit}> */}
                  Add
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

class BottomSummaryTO extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div
          className="fixed-bottom border-0 border-primary m-0 p-0 w-100"
          style={{
            backgroundColor: this.context.themeBorderColor,
          }}
        >
          {window.innerWidth >= 1100 ? (
            <>
              <Navbar
                className="position-sticky fixed-bottom m-0 p-0  border border-secondary"
                style={{ fontFamily: "Tahoma", color: "navy" }}
              >
                <Navbar.Collapse className="justify-content-start m-0 p-0 border-0">
                  <Row className="w-100">
                    <Col sm={8}>
                      <Nav className="border-0 m-0 p-0 w-100">
                        <OrderSummaryTO
                          myCart={this.props.myCart}
                          StoreCouponDetails={this.props.StoreCouponDetails}
                        ></OrderSummaryTO>
                      </Nav>
                    </Col>
                    <Col sm={4}>
                      <Nav>
                        <CheckoutTO
                          myCart={this.props.myCart}
                          coupon={this.props.coupon}
                          updateCartView={this.props.updateCartView}
                          tableName={this.props.tableName}
                          generatedInvoiceOrder={
                            this.props.generatedInvoiceOrder
                          }
                          StoreGeneratedInvoice={
                            this.props.StoreGeneratedInvoice
                          }
                          SaleDone={this.props.SaleDone}
                        ></CheckoutTO>
                      </Nav>
                    </Col>
                  </Row>
                </Navbar.Collapse>
              </Navbar>
            </>
          ) : (
            <>
              <Navbar
                className="position-sticky fixed-bottom m-0 p-0  border border-secondary"
                style={{ fontFamily: "Tahoma", color: "navy" }}
              >
                <Navbar.Collapse className=" m-0 p-0 border-0 w-100 bg-danger">
                  <Row className="w-100 border m-0 p-0">
                    <Col sm={12} className="w-100 border m-0 p-0">
                      <Nav className="border-0 m-0 p-0 w-100">
                        <OrderSummaryTO
                          myCart={this.props.myCart}
                          StoreCouponDetails={this.props.StoreCouponDetails}
                        ></OrderSummaryTO>
                      </Nav>
                    </Col>
                  </Row>
                </Navbar.Collapse>
              </Navbar>
              <Navbar
                className="position-sticky fixed-bottom m-0 p-0 border border-secondary"
                style={{ fontFamily: "Tahoma", color: "white" }}
              >
                <Navbar.Collapse className="justify-content-start m-0 p-0 ">
                  <Row className="w-100">
                    <Col sm={12}>
                      <Nav>
                        <CheckoutTO
                          myCart={this.props.myCart}
                          coupon={this.props.coupon}
                          updateCartView={this.props.updateCartView}
                          tableName={this.props.tableName}
                          generatedInvoiceOrder={
                            this.props.generatedInvoiceOrder
                          }
                          StoreGeneratedInvoice={
                            this.props.StoreGeneratedInvoice
                          }
                          SaleDone={this.props.SaleDone}
                        ></CheckoutTO>
                      </Nav>
                    </Col>
                  </Row>
                </Navbar.Collapse>
              </Navbar>
            </>
          )}
        </div>
      </>
    );
  }
}
